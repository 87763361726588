import Swiper, {
	Navigation, Pagination
} from 'swiper';
import { breakpoints } from '../../base/js/core'
const arrGallery = document.querySelectorAll('.s-gallery__picts');
arrGallery.forEach(gallery => {
	let carousel = gallery.querySelector('.swiper');
	let prev = gallery.querySelector('.s-gallery__prev');
	let next = gallery.querySelector('.s-gallery__next');
	let pagination = gallery.querySelector('.s-gallery__pagination')
	let options = {
		modules: [Navigation, Pagination],
		loop: true,
		slidesPerView: 1,
		spaceBetween: 0,
		navigation: {
			nextEl: prev,
			prevEl: next
		},
		pagination: {
			el: pagination,
			clickable: true
		}
	}
	let swiper;
	const swiperInit = () => {
		const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		if (width >= breakpoints.desktop) {
			if (carousel.classList.contains('swiper-initialized')) {
				swiper.destroy();
			}
		} else if (!carousel.classList.contains('swiper-initialized')) {
			swiper = new Swiper(carousel, options);
		}
	}
	swiperInit();
	window.addEventListener('resize', swiperInit)
})
