import Swiper, {
	Navigation, Pagination, Thumbs
} from 'swiper';
const thumbs = new Swiper('.s-detail__thumb-swiper', {
	modules: [Navigation, Pagination, Thumbs],
	slidesPerView: 4,
	spaceBetween: 6,
	watchSlidesProgress: true
});
const slider = new Swiper('.s-detail__swiper', {
	modules: [Navigation, Pagination, Thumbs],
	loop: true,
	autoHeight: true,
	navigation: {
		nextEl: '.s-detail__next',
		prevEl: '.s-detail__prev'
	},
	pagination: {
		el: '.s-detail__swiper-pagination',
		clickable: true
	},
	lazy: {
		loadPrevNext: true,
		loadPrevNextAmount: 2
	},
	thumbs: {
		swiper: thumbs
	}
});
