import Swiper, {
	Navigation, Pagination
} from 'swiper';

const arrCarousel = document.querySelectorAll('.b-experiment__slider');
arrCarousel.forEach(carousel => {
	let prev = carousel.querySelector('.b-experiment__prev');
	let next = carousel.querySelector('.b-experiment__next');
	let pagination = carousel.querySelector('.b-experiment__swiper-pagination');
	let $carousel = carousel.querySelector('.swiper');
	const swiper = new Swiper($carousel, {
		modules: [Navigation, Pagination],
		loop: true,
		slidesPerView: 1,
		spaceBetween: 0,
		navigation: {
			nextEl: prev,
			prevEl: next
		},
		pagination: {
			el: pagination,
			clickable: true
		}
	});
})

