import {
	slideToggle, slideUp
} from '../../base/js/core'

const location = (selector) => {
	const elms = document.querySelectorAll(selector)
	elms.forEach(box => {
		let head = box.querySelector('[data-head]')
		let dropdown = box.querySelector('[data-dropdown]')
		head.addEventListener('click', () => {
			box.classList.toggle('is-open')
			slideToggle(dropdown)
		})
	});
	document.addEventListener('click', (e) => {
		if (!e.target.closest(selector)) {
			elms.forEach(box => {
				let dropdown = box.querySelector('[data-dropdown]');
				box.classList.remove('is-open');
				slideUp(dropdown);
			});
		}
	})
}
location('.location')
