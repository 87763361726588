import Swiper, { Scrollbar, FreeMode, Mousewheel } from 'swiper';
const arrElms = document.querySelectorAll('.s-desc__desc');
arrElms.forEach(box => {
	let scrBar = box.querySelector('.swiper-scrollbar');
	let swiper = new Swiper(box, {
		modules: [Scrollbar, FreeMode, Mousewheel],
		direction: 'vertical',
		slidesPerView: 'auto',
		freeMode: true,
		scrollbar: {
			el: scrBar
		},
		mousewheel: true
	});
});
