import Swiper, {
	Navigation, Pagination
} from 'swiper';

const arrCarousel = document.querySelectorAll('.s-carousel');
arrCarousel.forEach(carousel => {
	let prev = carousel.querySelector('.s-carousel__prev');
	let next = carousel.querySelector('.s-carousel__next');
	let pagination = carousel.querySelector('.s-carousel__swiper-pagination');
	let $carousel = carousel.querySelector('.swiper');
	let slidesSm = carousel.dataset.slidesSm ? carousel.dataset.slidesSm : 2;
	let slidesMd = carousel.dataset.slidesMd ? carousel.dataset.slidesMd : 3;
	let slidesLg = carousel.dataset.slidesLg ? carousel.dataset.slidesLg : 3;
	const swiper = new Swiper($carousel, {
		modules: [Navigation, Pagination],
		loop: true,
		slidesPerView: 1,
		spaceBetween: 0,
		navigation: {
			nextEl: prev,
			prevEl: next
		},
		pagination: {
			el: pagination,
			clickable: true
		},
		breakpoints: {
			320: {
				slidesPerView: slidesSm
			},
			768: {
				slidesPerView: slidesMd
			},
			992: {
				slidesPerView: slidesLg
			}
		}
	});
})
