import Swiper, {
	Navigation, Pagination
} from 'swiper';
const arrSlider = document.querySelectorAll('.s-reviews__awards');
arrSlider.forEach(carousel => {
	let prev = carousel.querySelector('.s-reviews__prev');
	let next = carousel.querySelector('.s-reviews__next');
	let pagination = carousel.querySelector('.s-reviews__swiper-pagination');
	let $carousel = carousel.querySelector('.swiper');
	const swiper = new Swiper($carousel, {
		modules: [Navigation, Pagination],
		slidesPerView: 1,
		spaceBetween: 0,
		navigation: {
			nextEl: next,
			prevEl: prev
		},
		pagination: {
			el: pagination,
			clickable: true
		},
		breakpoints: {
			320: {
				direction: 'horizontal',
				slidesPerView: 2,
				spaceBetween: 0
			},
			768: {
				direction: 'vertical',
				slidesPerView: 5,
				spaceBetween: 30
			}
		}
	});
})
