const arrBtns = document.querySelectorAll('.s-clients__btn');
arrBtns.forEach(btn => {
	let list = btn.closest('.s-clients').querySelector('.s-clients__list')
	btn.addEventListener('click', e => {
		e.preventDefault();
		list.classList.toggle('is-open');
		let txt = list.classList.contains('is-open') ? 'Скрыть' : 'Смотреть все';
		btn.querySelector('span').textContent = txt
	})
})
