const arrOpen = document.querySelectorAll('.js-open-navbar');
const arrClose = document.querySelectorAll('.js-close-navbar');
const navBar = document.getElementById('mobile-navbar');

arrOpen.forEach(btn => {
	btn.addEventListener('click', e => {
		e.preventDefault();
		document.documentElement.classList.add('is-navbar-open');
		navBar.classList.add('is-open');
	})
})
arrClose.forEach(btn => {
	btn.addEventListener('click', e => {
		e.preventDefault();
		document.documentElement.classList.remove('is-navbar-open');
		navBar.classList.remove('is-open');
	})
})
