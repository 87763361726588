import { Fancybox } from '@fancyapps/ui'
import { slideToggle, slideUp, validate } from '../../base/js/core'
const options = {
	on: {
		done: (fancybox) => {
		}
	}
}
Fancybox.bind('[data-fancybox]', options);
const optionsExp = {
	on: {
		done: (fancybox) => {
			fancybox.container.classList.add('fancybox-experiment');
		},
		'Carousel.ready Carousel.change': (fancybox, carousel) => {
			let prev = fancybox.container.querySelector('.fancybox__nav .f-button.is-prev');
			let next = fancybox.container.querySelector('.fancybox__nav .f-button.is-next');
			let prevTxt = '';
			let nextTxt = ''
			for (let i = 0; i < carousel.slides.length; i++) {
				if (carousel.slides[i].el && carousel.slides[i].el.classList.contains('is-selected')) {
					nextTxt = carousel.slides[i].el.querySelector('.b-experiment').dataset.next
					prevTxt = carousel.slides[i].el.querySelector('.b-experiment').dataset.prev
				}
				prev.innerHTML = prevTxt;
				next.innerHTML = nextTxt
			}
		}
	}
}
Fancybox.bind('[data-experiment]', optionsExp);
const optionsGallery = {
	on: {
		done: (fancybox) => {
			fancybox.container.classList.add('fancybox-custom-gallery');
			fancybox.container.querySelector('.f-button.is-prev').innerHTML = '<svg width="60" height="57" viewBox="0 0 60 57" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(#filter0_i_35_1456)"><path fill-rule="evenodd" clip-rule="evenodd" d="M58.0178 14.6723L57.1955 44.4268L31.6902 41.1528L31.5994 53.9913L2.01107 28.4868L32.6563 2.98205L32.1284 16.925L58.0178 14.6723Z" fill="#99CC66"></path></g><path d="M58.5177 14.6861L58.5331 14.1255L57.9745 14.1742L32.6495 16.3778L33.1559 3.00097L33.1984 1.88039L32.3364 2.59773L1.69122 28.1025L1.23749 28.4801L1.68462 28.8656L31.2729 54.3701L32.0917 55.0758L32.0994 53.9949L32.1862 41.7206L57.1319 44.9227L57.6801 44.9931L57.6954 44.4406L58.5177 14.6861Z" stroke="#000001"></path><defs><filter id="filter0_i_35_1456" x="0.463867" y="0.778687" width="58.5845" height="55.3816" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix><feOffset dx="2" dy="-3.5"></feOffset><feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"></feComposite><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.00392157 0 0 0 0.09 0"></feColorMatrix><feBlend mode="normal" in2="shape" result="effect1_innerShadow_35_1456"></feBlend></filter></defs></svg>'
			fancybox.container.querySelector('.f-button.is-next').innerHTML = '<svg width="60" height="57" viewBox="0 0 60 57" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
				'<g filter="url(#filter0_i_35_1455)">\n' +
				'<path fill-rule="evenodd" clip-rule="evenodd" d="M2.00138 14.6726L2.82363 44.4268L28.3289 41.1528L28.4198 53.991L58.0082 28.4876L27.3629 2.98307L27.891 16.9257L2.00138 14.6726Z" fill="#99CC66"></path>\n' +
				'</g>\n' +
				'<path d="M2.04473 14.1745L1.48608 14.1259L1.50157 14.6864L2.32382 44.4406L2.33909 44.9931L2.88729 44.9227L27.8329 41.7206L27.9198 53.9945L27.9274 55.0754L28.7462 54.3697L58.3347 28.8663L58.7818 28.4809L58.3281 28.1032L27.6828 2.59875L26.8209 1.88142L26.8633 3.00199L27.3699 16.3784L2.04473 14.1745Z" stroke="#000001"></path>\n' +
				'<defs>\n' +
				'<filter id="filter0_i_35_1455" x="0.970703" y="0.779785" width="58.5845" height="55.3801" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">\n' +
				'<feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>\n' +
				'<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>\n' +
				'<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix>\n' +
				'<feOffset dx="2" dy="-3.5"></feOffset>\n' +
				'<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"></feComposite>\n' +
				'<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.00392157 0 0 0 0.09 0"></feColorMatrix>\n' +
				'<feBlend mode="normal" in2="shape" result="effect1_innerShadow_35_1455"></feBlend>\n' +
				'</filter>\n' +
				'</defs>\n' +
				'</svg>'
		}
	},
	Toolbar: {
		enabled: true,
		display: {
			left: [],
			middle: [],
			right: ['close']
		}
	},
	Thumbs: {
		type: 'classic'
	},
	Images: {
		zoom: false
	}
}
Fancybox.bind('[data-gallery]', optionsGallery);

const customSelect = (selector) => {
	const arr = document.querySelectorAll(selector);
	arr.forEach(select => {
		let head = select.querySelector('[data-head]');
		let dropdown = select.querySelector('[data-dropdown]');
		let arrInputs = select.querySelectorAll('input');
		let ttl = head.querySelector('[data-txt]');
		head.addEventListener('click', () => {
			select.classList.toggle('is-open');
			slideToggle(dropdown);
		});
		arrInputs.forEach(input => {
			input.addEventListener('change', e => {
				if (e.target.checked) {
					ttl.textContent = e.target.value
					select.classList.remove('is-open');
					slideUp(dropdown)
				}
			});
		})
	});
	document.addEventListener('click', (e) => {
		if (!e.target.closest(selector) && !e.target.closest('#choosing-city .btn-cancle')) {
			arr.forEach(select => {
				let dropdown = select.querySelector('[data-dropdown]');
				select.classList.remove('is-open');
				slideUp(dropdown)
			});
		}
	})
}
customSelect('.custom-select');
import inputmask from 'inputmask'
validate('.js-validate [required]')
const $inputMask = document.querySelectorAll('[data-mask]')
$inputMask.forEach($input => {
	const mask = $input.dataset.mask;
	inputmask({
		mask,
		showMaskOnHover: false
	}).mask($input)
})
const cancleCity = document.querySelector('#choosing-city .btn-cancle')
cancleCity.addEventListener('click', e => {
	e.preventDefault();
	e.target.closest('#choosing-city').querySelector('[data-head]').click();
})
const arrFiles = document.querySelectorAll('.input-file__inp');
function getChildElementIndex(element) {
	return Array.prototype.indexOf.call(element.parentNode.children, element)
}
arrFiles.forEach(input => {
	input.addEventListener('change', () => {
		let files = input.files;
		if (files.length) {
			let parent = input.closest('.input-file');
			if (parent.querySelector('ul')) {
				parent.querySelector('ul').remove();
			}
			const deleteFile = (element) => {
				const index = getChildElementIndex(element)
				let dt = new DataTransfer()
				for (let i = 0; i <= input.files.length - 1; i++) {
					if (i !== index) {
						dt.items.add(input.files[i])
					}
				}
				input.files = dt.files;
			}
			let list = document.createElement('ul');
			let dt = new DataTransfer();
			for (let i = 0; i < files.length && i < 5; i++) {
				let li = document.createElement('li');
				let img = document.createElement('img');
				let close = document.createElement('span');
				img.setAttribute('src', URL.createObjectURL(files[i]))
				dt.items.add(input.files[i])
				li.append(img)
				li.append(close)
				list.append(li)
				close.addEventListener('click', (e) => {
					deleteFile(e.target.closest('li'))
					e.target.closest('li').remove();
					if (!parent.querySelector('ul li')) {
						parent.querySelector('ul').remove();
					}
				})
			}
			input.files = dt.files;
			input.closest('.input-file').append(list)
		}
	})
})
const datepickers = document.querySelectorAll('.js-datepicker');
import flatpickr from 'flatpickr';
import { Russian } from 'flatpickr/dist/l10n/ru.js'
datepickers.forEach($datepicker => {
	flatpickr($datepicker, {
		locale: Russian,
		dateFormat: 'd.m.Y',
		minDate: 'today',
		disableMobile: 'true'
	});
});
const hideOpenPopup = (selector) => {
	const arrBtns = document.querySelectorAll(selector);
	arrBtns.forEach(btn => {
		let modal = btn.dataset.src ? btn.dataset.src : btn.getAttribute('href')
		btn.addEventListener('click', e => {
			e.preventDefault();

			Fancybox.close();

			// eslint-disable-next-line no-new
			new Fancybox([
				{
					src: modal
				}
			], {
				on: {
					close: (fancybox) => {
						if (fancybox.container.querySelector('.add-shows')) {
							let arrAddShows = document.querySelectorAll('.calculator-box__list-remove');
							let arrShowsInput = document.querySelectorAll('.add-shows__input');
							arrShowsInput.forEach(input => {
								input.checked = false
							});
							arrAddShows.forEach(label => {
								let input = document.getElementById(label.getAttribute('for'))
								input.checked = true;
							});
							document.querySelector('.add-order').click();
						}
					}
				}
			});
		});
	});
}
const convertStringToHTML = htmlString => {
	const parser = new DOMParser();
	const html = parser.parseFromString(htmlString, 'text/html');

	return html.body.firstElementChild;
}
const calc = () => {
	let totalPriceEl = document.getElementById('total-price');
	let totalTimeEl = document.getElementById('total-time');
	let arrChangeEls = document.querySelectorAll('.js-change-inp');
	let btnAddShow = document.getElementById('btn-add-show');
	const getTimeFromMins = (min) => {
		let hours = Math.trunc(min / 60);
		let minutes = min % 60;
		let time = hours ? `${hours} час.` : '';
		time += minutes ? `${minutes} мин.` : '';

		return time;
	}
	const counting = () => {
		let price = 0;
		let time = 0;
		arrChangeEls.forEach(input => {
			if (input.checked) {
				let parent = document.querySelector(input.dataset.parent);
				price += input.dataset.price * 1;
				if (parent) {
					parent.textContent = `${input.dataset.price * 1} руб.`
				}
				if (input.dataset.time) {
					time += input.dataset.time * 1;
				}
			}
		});
		totalTimeEl.textContent = getTimeFromMins(time);
		totalPriceEl.textContent = `${price * 1} руб.`
	};
	const addShow = () => {
		btnAddShow.addEventListener('click', () => {
			let arrShows = document.querySelectorAll('.add-shows__itm');
			let listShows = document.getElementById('add-shows-list');
			listShows.innerHTML = '';
			arrShows.forEach(itm => {
				let input = itm.querySelector('input');
				if (input.checked) {
					let id = input.getAttribute('id')
					let ttl = itm.querySelector('.add-shows__ttl').textContent + itm.querySelector('.add-shows__txt').textContent;
					let html = `<div class="calculator-box__list-itm"><div class="calculator-box__list-ttl">${ttl}</div> <label for="${id}" class="calculator-box__list-remove"><picture><source srcset="/local/layout/public/images/del-s.webp" type="image/webp"><source srcset="/local/layout/public/images/del-s.png" type="image/jpeg"><img class="calculator-box__list-del" src="/local/layout/public/images/del-s.webp" srcset="/local/layout/public/images/del-s.png" alt="Название картинки" loading="lazy" width="21" height="22"></picture></label></div>`;
					listShows.append(convertStringToHTML(html));
				}
			});
			let arrRemove = listShows.querySelectorAll('.calculator-box__list-remove');
			arrRemove.forEach(remove => {
				remove.addEventListener('click', () => {
					let input = document.getElementById(remove.getAttribute('for'));
					input.checked = false;
					remove.closest('.calculator-box__list-itm').remove();
					counting();
				});
			});
		})
	}
	addShow();
	counting();
	arrChangeEls.forEach(input => {
		input.addEventListener('change', () => {
			counting()
		});
	});
};
if (document.getElementById('btn-add-show')) {
	calc();
}
hideOpenPopup('.js-hide-open-modal');
window.openPopup = (modal) => {
	// eslint-disable-next-line no-new
	new Fancybox([
		{
			src: modal
		}
	]);
};
window.closePopup = () => {
	// eslint-disable-next-line no-new
	Fancybox.close();
};
