import Swiper, {
	Navigation, Pagination
} from 'swiper';
const arrGallery = document.querySelectorAll('.s-stars');
arrGallery.forEach(gallery => {
	let carousel = gallery.querySelector('.swiper');
	let prev = gallery.querySelector('.s-stars__prev');
	let next = gallery.querySelector('.s-stars__next');
	let pagination = gallery.querySelector('.swiper-pagination')
	let options = {
		modules: [Navigation, Pagination],
		loop: true,
		slidesPerView: 1,
		spaceBetween: 0,
		navigation: {
			nextEl: prev,
			prevEl: next
		},
		pagination: {
			el: pagination,
			clickable: true
		},
		breakpoints: {
			320: {
				slidesPerView: 1,
				slidesPerGroup: 1
			},
			992: {
				slidesPerView: 3,
				slidesPerGroup: 3
			}
		}
	}
	let swiper = new Swiper(carousel, options);
})
