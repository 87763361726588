import {
	breakpoints, convertStringToHTML,
	slideToggle, slideUp
} from '../../base/js/core';

const arrMenu = document.querySelectorAll('.menu');
const menu = (el) => {
	let btn = el.querySelector('.menu__head')
	let dropdown = el.querySelector('.menu__list');
	btn.addEventListener('click', e => {
		e.preventDefault();
		el.classList.toggle('is-open');
		slideToggle(dropdown)
	});
	const tabletView = () => {
		let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		let arrItems = el.querySelectorAll('.menu__itm');
		let list = el.querySelector('.menu__list');
		if (
			width < breakpoints.desktop
			&& width >= breakpoints.tablet
			&& arrItems.length > 4
			&& !el.querySelector('.menu__dropdown-tablet')
		) {
			let dropdwownT = document.createElement('div');
			let n = 0;
			let itmMoreString = '<div class="menu__itm menu__itm_more"><a href="#" class="menu__lnk_type-more menu__lnk">еще <img class="menu__lnk-arrow" src="images/menu/arrow.svg" width="28" height="17" loading="lazy"><div class="menu__shadow"></div></a></div>'
			let itmMore = convertStringToHTML(itmMoreString);
			dropdwownT.classList.add('menu__dropdown-tablet');
			arrItems.forEach(item => {
				n++;
				if (n > 4) {
					dropdwownT.append(item)
				}
			});
			itmMore.append(dropdwownT);
			list.append(itmMore);
			el.querySelector('.menu__lnk_type-more').addEventListener('click', e => {
				e.preventDefault();
				console.log('aba')
				el.querySelector('.menu__lnk_type-more').classList.toggle('is-active');
				slideToggle(el.querySelector('.menu__dropdown-tablet'));
			});
		} else if (
			el.querySelector('.menu__dropdown-tablet')
			&& width >= breakpoints.desktop
			|| width < breakpoints.tablet
			&& el.querySelector('.menu__dropdown-tablet')
		) {
			let arrItemsMore = el.querySelectorAll('.menu__dropdown-tablet .menu__itm');
			arrItemsMore.forEach(item => {
				list.append(item)
			});
			el.querySelector('.menu__itm_more').remove();
		}
	}
	tabletView();
	window.addEventListener('resize', () => {
		tabletView()
	})
}
arrMenu.forEach(el => {
	menu(el);
});

document.addEventListener('click', e => {
	if (!e.target.closest('.menu__head')) {
		let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		if (width < breakpoints.tablet) {
			arrMenu.forEach(menuI => {
				if (menuI.classList.contains('is-open')) {
					let dropdown = menuI.querySelector('.menu__list');
					slideUp(dropdown)
					menuI.classList.remove('is-open')
				}
			});
		}
	}
})
