import {
	getScrollbarWidth
} from '../../base/js/core'

document.addEventListener('DOMContentLoaded', () => {
	document.documentElement.setAttribute('style', `--scroll-bar-width: ${getScrollbarWidth()}px`)
})
const headerScroll = () => {
	const $header = document.querySelector('.s-header')
	const $headerFixedBlock = $header.querySelector('.s-header__sticky')
	let headerBottom = $header.offsetTop + $header.offsetHeight
	let flag = false
	const onScroll = () => {
		let scrollTop = window.pageYOffset || document.documentElement.scrollTop
		if (scrollTop > headerBottom) {
			if (!flag) {
				flag = true
				$header.classList.add('is-fixed')
				$headerFixedBlock.style.animationName = 'fadeInDown'
			}
		} else if (flag) {
			$header.classList.remove('is-fixed')
			$headerFixedBlock.removeAttribute('style')
			flag = false
		}
	}
	window.addEventListener('scroll', () => {
		onScroll()
	})
	window.addEventListener('resize', () => {
		onScroll()
	})
}
headerScroll()
