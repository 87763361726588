import inputmask from 'inputmask'
export const breakpoints = {
	phone: 0,
	tablet: 768,
	desktop: 992
}
export let slideUp = (target, duration = 500, callback) => {
	target.style.transitionProperty = 'height, margin, padding'
	target.style.transitionDuration = `${duration}ms`
	target.style.boxSizing = 'border-box'
	target.style.height = `${target.offsetHeight}px`
	// eslint-disable-next-line no-unused-expressions
	target.offsetHeight
	target.style.overflow = 'hidden'
	target.style.height = 0
	target.style.paddingTop = 0
	target.style.paddingBottom = 0
	target.style.marginTop = 0
	target.style.marginBottom = 0
	window.setTimeout(() => {
		target.style.display = 'none'
		target.style.removeProperty('height')
		target.style.removeProperty('padding-top')
		target.style.removeProperty('padding-bottom')
		target.style.removeProperty('margin-top')
		target.style.removeProperty('margin-bottom')
		target.style.removeProperty('overflow')
		target.style.removeProperty('transition-duration')
		target.style.removeProperty('transition-property')
		if (callback) {
			callback()
		}
	}, duration)
}
export let slideDown = (target, duration = 500, callback) => {
	target.style.removeProperty('display')
	let display = window.getComputedStyle(target).display

	if (display === 'none') {
		display = 'block'
	}
	target.style.display = display
	let height = target.offsetHeight
	target.style.overflow = 'hidden'
	target.style.height = 0
	target.style.paddingTop = 0
	target.style.paddingBottom = 0
	target.style.marginTop = 0
	target.style.marginBottom = 0
	// eslint-disable-next-line no-unused-expressions
	target.offsetHeight
	target.style.boxSizing = 'border-box'
	target.style.transitionProperty = 'height, margin, padding'
	target.style.transitionDuration = `${duration}ms`
	target.style.height = `${height}px`
	target.style.removeProperty('padding-top')
	target.style.removeProperty('padding-bottom')
	target.style.removeProperty('margin-top')
	target.style.removeProperty('margin-bottom')
	window.setTimeout(() => {
		target.style.removeProperty('height')
		target.style.removeProperty('overflow')
		target.style.removeProperty('transition-duration')
		target.style.removeProperty('transition-property')
		if (callback) {
			callback()
		}
	}, duration)
}
export let slideToggle = (target, duration = 500, callback) => {
	let display = window.getComputedStyle(target).display
	if (display === 'none') {
		slideDown(target, duration, callback)
	} else {
		slideUp(target, duration, callback)
	}
}
const getChildren = (n, skipMe) => {
	let r = []
	for (; n; n = n.nextSibling) {
		if (n.nodeType === 1 && n !== skipMe) {
			r.push(n)
		}
	}

	return r
}
export const getSiblings = (n) => {
	return getChildren(n.parentNode.firstChild, n)
}
export const prevAll = (element) => {
	const result = [];
	while (element = element.previousElementSibling) {
		result.push(element);
	}

	return result;
}
export const getScrollbarWidth = () => {
	const outer = document.createElement('div');
	outer.style.visibility = 'hidden';
	outer.style.overflow = 'scroll';
	outer.style.msOverflowStyle = 'scrollbar';
	document.body.appendChild(outer);
	const inner = document.createElement('div');
	outer.appendChild(inner);
	const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
	outer.parentNode.removeChild(outer);

	return scrollbarWidth;
}
export const validate = (selector) => {
	const inputsValidate = document.querySelectorAll(selector)
	inputsValidate.forEach($input => {
		$input.addEventListener('invalid', (e) => {
			e.preventDefault()
			$input.classList.add('is-invalid');
			if ($input.closest('.custom-select')) {
				$input.closest('.custom-select').classList.add('is-invalid');
			}
		})
		$input.addEventListener('focus', () => {
			$input.classList.remove('is-invalid')
			if ($input.closest('.custom-select')) {
				$input.closest('.custom-select').classList.add('is-invalid');
			}
			if ($input.getAttribute('type') === 'radio') {
				const radios = $input.closest('form').querySelectorAll(`input[name="${$input.getAttribute('name')}"]`)
				radios.forEach($radio => {
					$radio.classList.remove('is-invalid')
				})
			}
		})
	})
}
export const formValidateLife = (selector) => {
	const arrFormLife = document.querySelectorAll(selector);
	const hasInvalide = (arr) => {
		let errors = 0;
		arr.forEach(input => {
			if (input.matches(':invalid')) {
				errors++;
			}
		});

		return errors;
	}
	arrFormLife.forEach(form => {
		let btn = form.querySelector('[type="submit"]');
		let arrInput = form.querySelectorAll('input[required]');
		arrInput.forEach(input => {
			input.addEventListener('input', () => {
				if (hasInvalide(arrInput)) {
					btn.setAttribute('disabled', 'disabled');
				} else {
					btn.removeAttribute('disabled')
				}
			});
		})
	})
}
export const scrollBarWidth = () => {
	document.addEventListener('DOMContentLoaded', () => {
		document.documentElement.setAttribute('style', `--scroll-bar-width: ${getScrollbarWidth()}px`);
	});
}
export const tabs = (selector) => {
	const buttons = document.querySelectorAll(selector)
	buttons.forEach($button => {
		const tabID = $button.dataset.tab.substring(1) || $button.getAttribute('href').substring(1)
		const $tab = document.getElementById(tabID);
		const sBtns = getSiblings($button.parentNode);
		const sTabs = getSiblings($tab);
		$button.addEventListener('click', (e) => {
			e.preventDefault()
			$button.classList.add('is-active')
			$tab.classList.add('is-visible')
			sBtns.forEach($sBtn => {
				if ($sBtn.querySelector('[data-tab]')) {
					$sBtn.querySelector('[data-tab]').classList.remove('is-active')
				}
			})
			sTabs.forEach($sTab => {
				$sTab.classList.remove('is-visible')
			})
		})
	})
}
export const anchors = (selector) => {
	const arrAnchors = document.querySelectorAll(selector)
	arrAnchors.forEach($button => {
		$button.addEventListener('click', (e) => {
			e.preventDefault()
			const $el = document.getElementById($button.getAttribute('href').substring(1))
			if ($el) {
				const headerH = document.querySelector('.s-header__middle').offsetHeight + 20;
				let display = window.getComputedStyle($el).display
				if (display === 'none') {
					$el.style.display = 'block'
				}
				const elPosition = $el.getBoundingClientRect().top - headerH
				if (display === 'none') {
					$el.style.display = 'none'
				}
				window.scrollBy({
					top: elPosition,
					behavior: 'smooth'
				})
			}
		})
	})
}
export const counter = () => {
	const btnDisabled = (input) => {
		let minus = input.parentNode.querySelector('[data-minus]')
		let plus = input.parentNode.querySelector('[data-plus]')
		let max = input.getAttribute('max');
		let min = input.getAttribute('min');
		if (input.value === max) {
			plus.setAttribute('disabled', 'disabled')
		} else {
			plus.removeAttribute('disabled')
		}
		if (input.value === min) {
			minus.setAttribute('disabled', 'disabled');
		} else {
			minus.removeAttribute('disabled')
		}
	}
	document.addEventListener('click', evt => {
		let event = new Event('change');
		if (evt.target.closest('.counter__btn_minus')) {
			let input = evt.target.parentNodel
			input.stepDown();
			input.dispatchEvent(event);
			btnDisabled(input)
		}
		if (evt.target.closest('.counter__btn_plus')) {
			let input = evt.target.parentNodel
			input.stepUp();
			input.dispatchEvent(event);
			btnDisabled(input)
		}
	});
}
export const inputMask = (selector, attr) => {
	const arrEl = document.querySelectorAll(selector);
	arrEl.forEach(el => {
		let mask = el.getAttribute(attr)
		if (mask) {
			inputmask({
				mask,
				showMaskOnHover: false
			}).mask(el)
		}
	})
}
export const convertStringToHTML = (htmlString) => {
	const parser = new DOMParser();
	const html = parser.parseFromString(htmlString, 'text/html');

	return html.body.firstElementChild;
}
